@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

:root {
  --Spacing: 8px;
  --PrimaryColour: #2F6089;
  --SecondaryColour: #FB5102;
}


body {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: medium;
  font-style: italic;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;

  color: var(--PrimaryColour);
}

a {
  color: #ECECEC;
}

.content {
  margin: 0px auto;
  padding: 0px 40px;
  max-width: 1024px;
  container-type: inline-size;
}

.head {
  container-type: inline-size;
  position: relative;
  display: flex;
  height: 90cqi;
}


.headImage {
  position: absolute;
  top: -60px;
  left: -180px;
}

.heading {
  position: absolute;
  width: 100cqi;
  top: 120cqi;
  left:0cqi;
  z-index: 10;
  font-size: 1.6rem;
  font-weight: 500;
}

.BoldText {
  font-size: 1.6rem;
  font-weight: 500;
}

.bread {
  font-weight: 300;
  word-break: break-word;
}

.message {
  max-width: 1024px;
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--SecondaryColour);
  display: block;
  height: auto;
  margin: 100cqi auto auto;
}


.information {
  margin: -1rem;
  padding: 1rem;
  border-radius: 12px;

  background: rgba(250, 250, 250, 0.548);

  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid var(--PrimaryColour);
 /* border: 1px solid rgba(255, 255, 255, 0.71); */
/*   border: 1px #FFF;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(10px);
*/
  font-size: 1rem;
  font-weight: 400;
  text-shadow: 0px 0px 1px #ffffff;
}


.information p {
  margin: 0px;
  line-height: 1.8rem;
}

.sub-intro {
  padding-bottom: 0.5rem;
}


.info-bread {
  font-size: 1.2rem;
  padding-bottom: 1.2rem;
}

.info-bread:last-child {
  padding-bottom: 0px;

}

.numbers p {
  font-size: 1.2rem;
  margin-top: 10px;
  
}

.wraper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.seaFloor {
  position: absolute;
  height: auto;
  width: 100vw;
  z-index: -10;
  left: -40px;
  bottom: -240px;
  opacity: 0.3;
}


.process {
  position: relative ;
  left: -40px;
  width: 100vw;
  margin: 0px;
  background-color: rgba(8, 41, 83, 1);
  color: #ECECEC;
  font-size: 1.6rem;
  font-weight: 300;

  padding: 6vh 0px;
}


.process img  {
  max-width: 220px;
}

.process-info {
  margin: 10vh auto;
  padding: 40px;
  max-width: 1024px;
  container-type: inline-size;
}

.Innovating-info {
  margin: 10vh auto 0px;
  padding: 40px;
  max-width: 1024px;
  container-type: inline-size;
}

.hidden {
  display: none;
}

.active + .hidden {
  display: flex;
  position:absolute;
  margin: 0px;
  height: 100vh;
  background: gray;
z-index: 10000;
}


.result {
  position: relative ;
  left: -40px;
  width: 100vw;
  margin: 0px;
  background-color: rgba(8, 41, 83, 1);
  color: #ECECEC;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 2rem 0px;
}

.result-info {
  margin: 10vh auto;
  padding: 40px;
  max-width: 1024px;
  container-type: inline-size;

}

.result img {
 display: block;
 margin: auto auto;
 object-fit: cover;
 height: 100%;
 width: 100%;
}



/* Grid set up: */
.grid-info {
  display: grid;
  gap: 4rem;
  grid: auto / 1fr;
  align-content: flex-start;
  position: relative;
}

.grid-info {
  grid-template-areas: 'employment'
                       'volunteer'
                       'consultant'
                       'numbers';
                       margin-top: 100px;
                       margin-bottom: 200px;
  }

  /* Grid definitions: */
  
  .employment {
    grid-area: employment;
  }
  
  .volunteer {
    grid-area: volunteer;
  }
  
  .consultant{
    grid-area: consultant;
  }

  .numbers {
    grid-area: numbers;
  }



  .progress-grid {
    display: grid;
    gap: 3rem;
    grid-template-areas: 'bv'
                         'un'
                         'ea'
                         'vision'
                         'se'
                         'tp'
                         'input-img';
     text-align: center;
    }
  
  .bv {
    grid-area: bv;
  }
  
  .un {
    grid-area: un;
  }
  
  .ea {
    grid-area: ea;
  }
  
  .vision {
    grid-area: vision;
  }
  
  .se {
    grid-area: se;
  }
  
  .tp {
    grid-area: tp;
  }
  
  .input-img {
    grid-area: input-img;  
    margin: -40px auto;
  }

  
  .innovation-grid {
    display: grid;
    gap: 3rem;
    grid-template-areas: 'sketch'
                          'bp'
                          'ui'
                          'uj'
                          'stratergy'
                          'innovating-img';
    text-align: center;
  }
  
  .sketch {
    grid-area: sketch;
  }
  .bp {
    grid-area: bp;
  }
  .ui {
    grid-area: ui;
  }
  .uj {
    grid-area: uj;
  }
  .stratergy {
    grid-area: stratergy;
  }
  .innovating-img {
    grid-area: innovating-img;
    margin: -40px auto;
  }

  .ciam-grid {
    display: grid;
    gap: 1rem;
    grid-template-areas: 'ciamDesc'
                          'ciamMob'
                          'ciamInfo';
  }
  
  .ciamDesc {
    grid-area: ciamDesc;
  }
  .ciamMob {
    grid-area: ciamMob;
  }
  .ciamInfo {
    grid-area: ciamInfo;
  }


  .rcs-grid {
    display: grid;
    gap: 2rem;
    grid-template-areas: 'rcs1'
                          'rcs2'
                          'rcsInfo';
   }

.RCS1 {
  grid-area: rcs1;
}
.RCS2 {
  grid-area: rcs2;
}

.RCSInfo {
  grid-area: rcsInfo;
}

