

@media only screen and (min-width: 1105px) {
      .seaFloor {
        left: calc((100vw - 1024px) / -2);
      }
      
      .process {
        left: calc((100vw - 1024px) / -2) ;
    }

    .result {
      left: calc((100vw - 1024px) / -2) ;
    }
}
 


  @media only screen and (min-width: 800px) {
    
    .head {
      height: 70cqi;

      }
    
    .headImage {
      width: 100cqi;
      top: -250px;
      left: -400px;
    }
    
    .heading {
      position: absolute;
      width: 50cqi;
      top: 44cqi;
      left:44cqi;
      z-index: 10;
      font-size: 1.6rem;
      font-weight: 500;
    }

    .message {

        margin: 0px auto auto;
      }

      .result img {

        width: auto;
       }
       
    
    .grid-info {
        grid: auto / 1fr 1fr;
      }

    .grid-info { grid-template-areas: 'employment consultant'
                                       'volunteer consultant'
                                       'numbers numbers';
    }

    .progress-grid {
      grid: auto / 1fr 1fr 1fr;
       grid-template-areas:'vision input-img tp'
                           'un input-img se' 
                           'bv input-img ea';
      }


    .innovation-grid {
      grid: auto / 1fr 1fr 1fr;
      grid-template-areas: 'sketch innovating-img uj'
      'bp innovating-img stratergy'
      'ui innovating-img empty';
    }

    .grid-right {
      text-align: right;
      justify-items: right;
     
  }
    .grid-left {
      text-align: left;
      justify-items: left;
  }

  .ciam-grid {
    grid: auto / 1fr 1fr;
    grid-template-areas: 'ciamDesc ciamMob'
                          'ciamInfo ciamInfo';
  }

  .rcs-grid {
    grid: auto / 1fr 1fr;
    grid-template-areas: 'rcs1 rcs2'
                          'rcsInfo rcsInfo';
  }


    .bg-containter {
         rotate: 0deg;
  }

}